import axios from 'axios';
import store from '../../../store/index'
import {backendApi} from "../../backend-api-sr"

export const rollingReport = (() =>{
    const fetchFilter = ( async (subPath) => {
        var url = '/api/v3/sm/mill-data-analysis/rolling-report-controller/filter'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchData = ( async (subPath) => {
        var url = '/api/v3/sm/mill-data-analysis/rolling-report-controller'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {fetchFilter, fetchData};
})()