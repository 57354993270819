<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid class="mt-1">
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete dense solo class="ma-0 border-12" hide-details=true v-model="origin"
                                    :items="origins" default="" item-value="origin_id" item-text="descr" label="Origin"
                                      clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Start Date *" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period"  
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Date" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-btn class="border-12" style="padding: 20px;" color="info" elevation="2" small @click="getPullData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" >
                                    <download-excel
                                        class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export
                                    </download-excel> </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="formattedData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="30"
                                    fixed-header
                                    height="476"
                                    :divider="true"
                                    :light="true"
                                    :search="search"
                                >       
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="cyan-text text-darken-3 m-0">Data</h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search"
                                                    append-icon="mdi-magnify" label="Search" dense single-line
                                                    hide-details></v-text-field>
                                                <v-btn small color="#005c37" class="py-5 mr-3 text-white border-12">
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                                        :before-generate="startDownload" :before-finish="finishDownload">
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template> 
                                <!-- <template v-slot:detailData.wgt="{ item }">
                                    {{ formatNumber(item.wgt) }}
                                </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {rollingReport} from "../../../backend-api/sm/analysis/rolling_report"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data(){
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SM',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Mill Data Analysis',
                    disabled: true,
                    href: '/admin/sm',
                },
                {
                    text: 'Rolling Report',
                    disabled: true
                }
            ],
            // filter
            origin:'',
            origins:[],
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            tableVisible:false,
            header:[
                {
                    text: 'Origin',
                    align: 'start',
                    value: 'origin', 
                    align:'left'},
                { text: 'HRP ID', value: 'hrp_id' , align:'left'},
                { text: 'Thick HRP', value: 'thick_hrp' , align:'left'},
                { text: 'Width HRP', value: 'width_hrp' , align:'left'},
                { text: 'Weight HRP', value: 'wgt_hrp' , align:'left'},
                { text: 'Coil ID', value: 'coil_id' , align:'right'},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Weight CRC', value: 'wgt_crc' , align:'right'},
                { text: 'Scrap Weight', value: 'PL1' , align:'right'},
                { text: 'Yield', value: 'yield' , align:'right'},
                
            ],
            data:[],
            headersColumn:{
                'Origin': 'origin', 
                'HRP ID': 'hrp_id',
                'Thick HRP' : 'thick_hrp',
                'Width HRP' : 'width_hrp',
                'Weight HRP' : 'wgt_hrp',
                'Coil ID': 'coil_id',
                'Thick': 'thick',
                'Width': 'width',
                'Weight CRC': 'wgt_crc',
                'Scrap Weight': 'PL1',
                'Yield': 'yield'
            },
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getOrigin()
        this.$store.dispatch('setOverlay', false)
    },
    computed:{
        formattedData() {
            return this.data.map(item => ({
                ...item,
                origin: item.origin,
                hrp_id : item.hrp_id,
                thick_hrp : this.$store.getters.convertToCurrencyUs(item.thick_hrp),
                width_hrp : this.$store.getters.convertToCurrencyUs(item.width_hrp),
                wgt_hrp : this.$store.getters.convertToCurrencyUs(item.wgt_hrp),
                coil_id : item.coil_id,
                thick : this.$store.getters.convertToCurrencyUs(item.thick),
                width : this.$store.getters.convertToCurrencyUs(item.width),
                wgt_crc : this.$store.getters.convertToCurrencyUs(item.wgt_crc),
                PL1 : this.$store.getters.convertToCurrencyUs(item.PL1),
                yield : this.$store.getters.convertToCurrencyUs(item.yield)
            }));
        },
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getOrigin(event){
            const respData = await rollingReport.fetchFilter('', null, false, false, false)
            if (respData.status === 200) {
                this.origins = respData.data.data
            }
        },
        async getPullData(event){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill start date",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
                const respData = await rollingReport.fetchData(`?origin=${this.origin}&start_date=${periodStart}&end_date=${periodEnd}`, null, false, false, false)
                if (respData.status === 200) {
                    this.data = respData.data.data
                    this.tableVisible = true
                    this.$store.dispatch('setOverlay', false)
                }  else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    return false
                }
            }
        },
        async exportExcel(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill start date",
                    visible: true
                };
                return false;
            } else {
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
                const respData = await rollingReport.fetchData(`?origin=${this.origin}&start_date=${periodStart}&end_date=${periodEnd}`, null, false, false, false)
                var a = respData.data.data
                return a.map(item => ({
                    ...item,
                    origin: item.origin,
                    hrp_id : item.hrp_id,
                    thick_hrp : this.$store.getters.convertToCurrencyUs(item.thick_hrp),
                    width_hrp : this.$store.getters.convertToCurrencyUs(item.width_hrp),
                    wgt_hrp : this.$store.getters.convertToCurrencyUs(item.wgt_hrp),
                    coil_id : item.coil_id,
                    thick : this.$store.getters.convertToCurrencyUs(item.thick),
                    width : this.$store.getters.convertToCurrencyUs(item.width),
                    wgt_crc : this.$store.getters.convertToCurrencyUs(item.wgt_crc),
                    PL1 : this.$store.getters.convertToCurrencyUs(item.PL1),
                    yield : this.$store.getters.convertToCurrencyUs(item.yield)
                }));
            }
        },

        startDownload(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill start date",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch:{},
}
</script>

<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>